export const baseurl = 'https://api-prokpi.viserx.net/api'
// export const baseurl = 'https://api.demo.prokpi.net/api'
// export const baseurl = 'https://api.app.prokpi.net/api'
// export const baseurl = 'http://kpi-module.test/api'

// export const baseurl = 'https://api-kpi.seoviserx.com/api'


export const PUSHER_APP_ID = '1543662'
export const PUSHER_APP_KEY = 'df38110fa36d67c5ab79'
export const PUSHER_APP_SECRET = '6c4f266e03e180acf1c2'
export const PUSHER_APP_CLUSTER = 'ap1'

export const CAPTCHA_SITEKEY = "6LfvDlEnAAAAAJxgz5Mhzaoqb9EPfVzKk5PvCgLV"

export const STRIPE_SECRET = 'fgbf'