import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const getAllData = createAsyncThunk(
  "tickets/getAllData",
  async (params) => {
    const response = await axios.get("/tickets", { params })
    return response.data
  }
)
// subscriptions list
export const storeData = createAsyncThunk(
  "tickets/storeData",
  async (data, { dispatch }) => {
    const res = await axios.post("/tickets", data)
    if (res?.status === 200 || res?.status === 201) {
      dispatch(getAllData())
      return { status: "success" }
    } else {
      return { status: "error", error: res?.response?.data }
    }
  }
)

export const getAllType = createAsyncThunk("tickets/getAllType", async () => {
  const response = await axios.get("/ticket-type")
  return response.data
})

export const getAllMessage = createAsyncThunk(
  "tickets/getAllMessage",
  async (id) => {
    const response = await axios.get(`/ticket-message/${id}`)
    return response.data
  }
)

export const sendMessage = createAsyncThunk(
  "tickets/sendMessage",
  async (data) => {
    const res = await axios.post("/ticket-message", data)
    return res.data
  }
)
export const sendFile = createAsyncThunk(
  "tickets/sendFile",
  async (data) => {
    const res = await axios.post("/ticket-file", data)
    return res.data
  }
)

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState: {
    permission: {},
    data: [],
    loading: false,

    statusOptions: [
      { label: "Open", value: 1 },
      { label: "Closed", value: 3 }
    ],
    typeOptions: [],

    message: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.data = action?.payload?.tickets?.data
        state.permission = action?.payload?.permission
        state.total = action?.payload?.tickets?.total
      })
      .addCase(getAllType.fulfilled, (state, action) => {
        state.typeOptions = action.payload?.map((item) => ({
          label: item?.name,
          value: item?.id
        }))
      })

      .addCase(getAllMessage.fulfilled, (state, action) => {
        state.message = action?.payload
      })
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})
export const { setLoading } = ticketsSlice.actions

export default ticketsSlice.reducer
